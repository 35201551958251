<template>
  <div class="announcement">
    {{ announcement.text }}
  </div>
</template>

<script setup>
import { useAnnouncementStore } from '@/stores'
const announcement = useAnnouncementStore()
</script>

<style lang="scss">
.announcement {
  position: absolute;
  left: 0; top: 0; right: 0; bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba($gray-400, 0.95);
  z-index: ($zindex-overlay + 1);
  color: $white;
  padding:0 map-get($spacers, 4);
}
</style>
