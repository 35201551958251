<template>
<div id="permissions">
  <div id="permissions-states" class="permissions layout--base">
    <div class="layout--base__header">
      <a-logo />
    </div>
    <div class="layout--base__content">
      <div class="layout--base__content__wrapper">
        <h2 class="text--center">{{ $t('permissions.title') }}</h2>
        <p class="text--center mb--3">{{ $t('permissions.text') }}</p>
        <permissions-item v-if="isCameraRecommended" device="camera" />
        <permissions-item v-if="isMicrophoneRecommended" device="microphone" />
        <permissions-item v-if="isLocationRecommended" device="location" />
        <div class="d--flex justify-content--end">
          <div 
            v-if="state.matches('ui.insufficientPermissions')"
          >
            <a-button
              variant="link-primary"
              class="me--3"
              @click="send('continue')"
            >
              {{ $t('permissions.actions.continue')}}
            </a-button>
          </div>
          <div 
            v-if="state.matches('ui.insufficientPermissions')"
          >
            <a-button
              @click="send('init')"
            >
              {{ $t('permissions.actions.tryAgain')}}
            </a-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script setup>
import { computed, nextTick } from 'vue'
import { usePermissionsMachine } from '@/state'
import { useAppStore, useUserStore } from '@/stores'
import { useLocalStream, useLocation } from '@/composables'
import { devicesShortNamesMap } from '@browser'

const app = useAppStore()
const user = useUserStore()
const { addTrack, stream: localStream } = useLocalStream()
const { getLocation } = useLocation()
const isCameraRecommended = app.isCameraRecommended
const isMicrophoneRecommended = app.isMicrophoneRecommended
const isLocationRecommended = app.isLocationRecommended

const { state, send, service } = usePermissionsMachine()
const hasCameraPermission = computed(() => state.value.context.hasCameraPermission)
const hasMicrophonePermission = computed(() => state.value.context.hasMicrophonePermission)

const handleGetStream = (device) => {
  addTrack(device)
    .then((response) => {
      app.transition(`${device}_available`.toUpperCase())
      user.profile[device] = 'on'
      send(`${device}StreamSuccess`)
      if (response.track) {
        if (response.track.muted) {
          app.transition(`MUTE_${device.toUpperCase()}`)
        } else {
          const muteHandler = (event) => {
            if (device === 'camera') {
              setTimeout(() => {
                localStream.video.stop()
                addTrack('camera')
                  .then(() => {
                    app.transition('camera_available'.toUpperCase())
                    user.profile['camera'] = 'on'
                  })
                  .catch((error) => {
                    app.transition('camera_not_available'.toUpperCase())
                  })
              }, 3000)
            }
          }
          response.track.addEventListener('mute', () => muteHandler())
        }
      }
    })
    .catch((error) => {
      console.error(error)
      app.transition(`${device}_not_available`.toUpperCase())
      send(`${device}StreamDenied`)
    })
} 

const handleGetLocation = () => {
  getLocation()
    .then((location) => {
      user.setLocation(location.value)
      location.value.source === 'browser' ? send('locationSuccess') : send('locationDenied')
    })
    .catch(() => send('locationDenied'))
}


const handleValidatePermissions = () => {
  if (isCameraRecommended && !hasCameraPermission.value) {
    send('insufficientPermissions')
    return
  }
  if (isMicrophoneRecommended && !hasMicrophonePermission.value) {
    send('insufficientPermissions')
    return
  }
  send('sufficientPermissions')
}

const stateHandlersMap = new Map([
  ['init', () => {
    app.loaded()
    send('camera')
  }],
  ['camera', () => isCameraRecommended ? send('cameraRecommended') : send('cameraNotRecommended')],
  ['getCameraStream', () => handleGetStream('camera')],
  ['microphone', () => isMicrophoneRecommended ? send('microphoneRecommended') : send('microphoneNotRecommended')],
  ['getMicrophoneStream', () => handleGetStream('microphone')],
  ['location', () => isLocationRecommended ? send('locationRecommended') : send('locationNotRecommended')],
  ['getLocation', () => handleGetLocation()],
  ['validatePermissions', () => nextTick(() => handleValidatePermissions())],
  ['join', () => setTimeout(() => {
    app.transition('join')
  }, 1000)],
  ['insufficientPermissions', () => {}]
])

service.subscribe((state) => stateHandlersMap.get(state.value.ui)())
</script>

<style lang="scss">
$permissions-bg: $body-bg;
$permissions-loader-duration:   1s;
$permissions-opacity: 0.5;
$permissions-asking-opacity: 1;
$permissions-asking-transform: 1.15;
$permissions-granted-opacity: 1;
$permissions-granted-transform: 1;

.permissions {
  z-index: ($zindex-modal - 1);
  background: $permissions-bg;
  .box {
    transition: transform $duration-fast, opacity $duration-fast;
    opacity: $permissions-opacity;
    &__content {
      padding-bottom: 0;
    }
    &.--denied {
      animation: shake $duration-slow linear;
      animation-delay: ($duration-fast * 1.25);
    }
    &.--granted,
    &.--denied,
    &.--connected {
      transform: scale($permissions-granted-transform);
      opacity: $permissions-granted-opacity;
    }
    &.--asking {
      color: $body-color;
      transform: scale($permissions-asking-transform);
      opacity: $permissions-asking-opacity;
    }
    &.--connecting {
      transform: scale($permissions-asking-transform);
      opacity: $permissions-asking-opacity;
    }
  }
  &__modal {
    &.visible {
      z-index: ($zindex-modal - 1);
      background: $backdrop;
    }
  }
  @media (max-width: $breakpoint-sm) {
    .layout--base__content {
      padding-top: 0;
    }
  }
}

.permission {
  display: flex;
  padding: map-get($spacers, 3) 0;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: map-get($spacers, 3);
  }
  &__wrapper {
    width: 100%;
  }
  &__content {
    text-align: left;
    margin: 0;
  }
  &__header {
    display: flex;
    justify-content: space-between;
    h4 {
      margin-bottom: 0;
    }
  }
  @media (max-width: $breakpoint-sm) {
    padding: map-get($spacers, 2) 0;
    &__header {
      display: block;
    }
  }
}
</style>
