import { logger } from '../../../logger/src'
import Receiver from '../helpers/Receiver.js'

export default class Strategy {
  constructor () {
    this.subscribers = []
    this.receiver = new Receiver()
  }

  getEndpoint = (channel) => {
    for (const endpoint in this.endpoints) {
      if (this.endpoints[endpoint].endpoint.channels.includes(channel)) {
        return this.endpoints[endpoint]
      }
    }
  }

  subscribe = (event, handler) => {
    this.subscribers.push({ event, handler})
    return this
  }

  publish = (event, data = null) => {
    this.subscribers.forEach((subscriber) => {
      if (subscriber.event === event.type) {
        subscriber.handler(event)
      }
    })
  }

  replaceTrack (type, oldTrack, newTrack, stream) {
    const peer = this.getEndpoint(type)
    peer.endpoint.replaceTrack(oldTrack, newTrack, stream)
    this.publish({ type: `strategy:replaceTrack:${type}`, data: { stream }})
  }
}
