<template>
  <a-layout-page class="join anim">
    <template #header>
      <a-logo brand />
    </template>
    <template #content>
      <div class="box">
        <div class="join__panel">
          <div class="join__panel__media">
            <video
              v-if="app.isCameraRecommended"
              class="media media--cover"
              ref="video"
              autoplay
              playsinline
              muted
            ></video>
            <a-icon
              v-else
              name="person"
              :width="83"
              :height="100"
            />
          </div>
          <div class="join__panel__bar">
            <join-device-control-button  v-if="isMicrophoneRecommended" device="microphone" id="microphone" />
            <join-device-control-button  v-if="isCameraRecommended"  device="camera" id="camera" />
            <join-devices-button />
            <join-qr-button />
          </div>
        </div>
        <div class="box__content">
          <a-alert 
            v-if="response"
            :type="responseType"
          >
            {{ response }}
          </a-alert>
          <form v-if="!areControlsHidden">
            <div class="form__group" >
              <label class="sr-only" for="conference">Conference</label>
              <input 
                class="form__control text--center"
                type="text"
                name="conference"
                v-model="session.conference" 
                :placeholder="$t('join.enterSessionId')"
                :disabled="isConferenceInputDisabled"
              @input="canJoin() ? send('canJoin') : send('init')"
              />
            </div>
            <div class="form__group" >
              <label class="sr-only" for="key">Key</label>
              <input
                class="form__control text--center"
                type="text"
                name="key"
                :placeholder="$t('join.enterKey')" 
                v-model="session.key" 
                @input="canJoin() ? send('canJoin') : send('init')"
                :hidden="isKeyInputHidden"
                :disabled="isKeyInputDisabled"
              />
            </div>
            <div class="form-group">
              <a-button
                v-if="!isJoinButtonHidden"
                block
                :disabled="isJoinButtonDisabled || isJoining"
                @click="joinHandler"
              >
                {{ isJoining ? $t('join.action.joiningSession') : $t('join.action.startSession') }}
              </a-button>
            </div>
          </form>
        </div>
      </div>
    </template>
    <template #footer>
      <p class="text--small w--100 text--center" v-html="$t('join.agreement')"></p>
    </template>
    <template #image>
      <a-clock-image />
    </template>
  </a-layout-page>
</template>

<script setup>
import { onMounted, onUnmounted, computed, ref, watchEffect, watch } from 'vue'
import container from '@di'
import { getURLParameter, matchURLParameter } from '@/utils'
import { useJoinMachine } from '@/state'
import { useLocalStream, useRepository, useWearML } from '@/composables'
import { locationMessages } from '@/messages'

import { 
  useAppStore,
  useUserStore,
  useSessionStore
} from '@/stores'

import {
  SessionNotStartedError,
  SessionNotFoundError,
  SessionExpiredError,
  PartyHasAlreadyJoinedError,
  UserNotAuthorizedError,
  UserNotFoundError,
} from '@communication/errors'
import { useTranslation } from 'i18next-vue'

const agent = container.agent
const video = ref()
const session = useSessionStore()
const app = useAppStore()
const user = useUserStore()
const { state, send, service } = useJoinMachine()
const { video: localVideoStream } = useLocalStream()


const wearML = useWearML()

/* TODO: QRCODE state */
console.log('TODO:', state.value.value.qrcode)

const { t, i18next } = useTranslation()

const areControlsHidden = computed(() => state.value.context.areControlsHidden)
const isJoinButtonDisabled = computed(() => state.value.context.isJoinButtonDisabled)
const isJoinButtonHidden = computed(() => state.value.context.isJoinButtonHidden)
const isConferenceInputDisabled = computed(() => state.value.context.isConferenceInputDisabled)
const isKeyInputDisabled = computed(() => state.value.context.isKeyInputDisabled)
const isKeyInputHidden = computed(() => state.value.context.isKeyInputHidden)
const response = computed(() => state.value.context.response)
const responseType = computed(() => state.value.context.responseType)
const shouldAutoJoin = computed(() => state.value.context.shouldAutoJoin)

const isCameraRecommended = app.isCameraRecommended
const isMicrophoneRecommended = app.isMicrophoneRecommended
const isJoining = ref(false)

const joinEventHandlersMap = new Map([
  ['validateSession', () => validateSession()],
  ['validateSessionAndJoin', () => validateSession()]
])

const serviceSubscription = service.subscribe((currentState) => {
  if (joinEventHandlersMap.has(currentState.event.type)) {
    joinEventHandlersMap.get(currentState.event.type)(currentState)
  }
})

const canJoin = () => {
  const format =  /^(\d{3}-){3}\d{3}$/
  return !!(session?.conference?.match(format) && session.key.length > 0)
}

watchEffect(() => {
  if (video?.value) video.value.srcObject = localVideoStream.value
})

onMounted(async () => {
  const key = getURLParameter('k') || ''
  const format =  /^(\d{3}-){3}\d{3}$/
  const conference =
    getURLParameter('conference') ||
    matchURLParameter(format) ||
    ''
  session.conference = conference
  session.key = key

  send('hasStream')
  app.loaded()
  if (canJoin()) {
    send('canJoinUsingURLParams')
    send('validateSession')
  }
  wearML.refresh()
})

onUnmounted(() => {
  serviceSubscription.unsubscribe()
})

const validateSession = async (event) => {
  const { key, conference: callId } = session
  const { configuration } = app
  const { app: { type: appType }, session: { api: sessionApiEndpoint }} = configuration
  isJoining.value = true
  try {
    const sessionInfo = await agent.createSession(sessionApiEndpoint, appType, key, callId)
    session.setInfo(sessionInfo)

    const isSessionAudioMuted = session?.info?.allowStartMuted
    if (isSessionAudioMuted) {
      app.transition('MUTE_MICROPHONE', { isSilent: true })
    }

    send('ready')

    if (shouldAutoJoin.value) {
      joinSession()
    }
    isJoining.value = false
  } catch (error) {
    isJoining.value = false
    if (error instanceof SessionNotStartedError) {
      send({ type: 'sessionNotStarted', message: t(`join.${error.code}`).replace('{value}', new Date(error.response.sessionStart).toLocaleString(i18next.language)) })
    } else if (error instanceof SessionNotFoundError) {
      send({ type: 'sessionNotFound', message: t(`join.${error.code}`) })
    } else if (error instanceof SessionExpiredError) {
      send({ type: 'sessionExpired', message: t(`join.${error.code}`) })
    } else if (error instanceof UserNotFoundError) {
      send({ type: 'userNotFound', message: t(`join.${error.code}`) })
    } else if (error instanceof UserNotAuthorizedError) {
      send({ type: 'userNotAuthorized', message: t(`join.${error.code}`) })
    }
    console.error(error)
  }
  return false
}

const joinHandler = async (event) => {
  if (state.value.matches('ui.canJoin')) {
    send('validateSessionAndJoin')
  } else {
    joinSession()
  }
}

const joinSession = async () => {

  if (state.value.matches('ui.canJoin')) {
    const isValid = await validateSession()
    if (!isValid) return
  }

  try {
    const { user: userResponse, configuration: configurationResponse } = await agent.session.join()

    user.setUser(
      userResponse.id,
      userResponse.email,
      userResponse.fullname,
      userResponse.party
    )
    session.setConfiguration(configurationResponse)
    app.transition('meeting')
    await agent.call()
    
    const { sendWsEvent } = useRepository()
    sendWsEvent(locationMessages.locationSet(user.location))

  } catch (error) {
    // error.readableMessage = this.locize.get(`join.action.generalError`)
    if (error instanceof PartyHasAlreadyJoinedError) {
      send({ type: 'userAlreadyJoined', message: t(`join.${error.code}`) })
    }
    console.error(error)
  }
}
</script>

<style lang="scss">
$join-media-bg: $purple-200;
$join-media-color: $white;
$join-bar-spacer: map-get($spacers, 2);
$join-avatar-size: 2rem;

.join {
  z-index: $zindex-default;
  &__panel {
    position: relative;
    margin-bottom: $box-spacer;
    &__media {
      position: relative;
      width: 100%;
      padding-top: (divide(9, 16) * 100%);
      height: 0;
      background-color: $join-media-bg;
      color: $join-media-color;
      .media,
      .icon {
        position: absolute;
      }
      .media {
        left: 0;
        top: 0;
      }
      .icon {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: 35%;
      }
    }
    &__bar {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      .btn {
        margin: $join-bar-spacer ($join-bar-spacer * 0.5);
      }
    }
  }
  &__contact {
    display: flex;
    align-items: center;
    &__avatar {
      flex: 0 0 $join-avatar-size;
      img {
        display: block;
        width: $join-avatar-size;
        height: $join-avatar-size;
        border-radius: 50%;
      }
    }
    &__text {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .layout__image {
    right: $box-spacer-lg;
    bottom: $box-spacer-lg;
    width: calc(50vw - #{$layout-base-max-width * 0.5} - #{$box-spacer-lg * 2});
  }
  @media (max-width: 1200px) {
    .layout__image {
      display: none;
    }
  }
  @media (max-height: $breakpoint-sm) {
    .layout--base__header {
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
    }
    .layout--base__content {
      padding-top: 0;
    }
    h1 {
      display: none;
    }
  }
  @media (min-width: $breakpoint-sm) {
    .logo__brand {
      transition-property: opacity;
      transition-duration: $duration-slow;
      transition-delay: $duration-fast;
      opacity: 0;
    }
    &.anim .logo__brand {
      opacity: 1;
    }
  }
  @media (max-height: $breakpoint-sm) and (max-width: $breakpoint-lg) {
    $pictogram-size: 30px;
    .logo {
      position: absolute;
      width: $pictogram-size;
      left: 50%;
      transform: translateX(($layout-base-max-width * -0.5) - $pictogram-size) translateX($layout-spacer-sm * -1);
      overflow: hidden;
    }
  }
}
</style>
