import { debounce } from 'debounce'
import container from '@di'
import { Agent, messenger, Procedures } from '@communication'
import { LocalStream, KurentoStream, ScreensharingStream, Speaker } from '@stream'
import { setupFabric } from '@/libraries'
import { useUserStore } from '@/stores'

export default function setup (app) {
  setupApp()
  setupFabric()
  setupEventListeners()
}

function setupApp () {
  const initialState = window.__INITIAL_STATE__
  const initialConfiguration = window.__CONFIGURATION__

  const { isMicrophoneRecommended, isCameraRecommended } = initialState.app
  
  container.messenger = messenger
  
  const agent = new Agent(initialConfiguration)
  const procedures = new Procedures()
    
  const localStream = new LocalStream({ 
    audio: isMicrophoneRecommended,
    video: isCameraRecommended
  }, initialState.app.idealConstraints)

  const kurentoStream = new KurentoStream()
  const screensharingStream = new ScreensharingStream()

  container.agent = agent
  container.procedures = procedures
  container.localStream = localStream
  container.screensharingStream = screensharingStream
  container.kurentoStream = kurentoStream

  const speaker = new Speaker()
  container.speaker = speaker

  if (initialState.app.isHmtDevice) {
    const wmlScript = document.createElement('script')
    wmlScript.src = '/libs/wearml/wearml_engine-updated.js'
    document.body.appendChild(wmlScript)
    document.body.setAttribute('tabindex', '-1')
    document.body.setAttribute('title', 'hf_no_overlay')
  }  
}

function setupEventListeners () {
  const user = useUserStore()
  window.addEventListener('resize', debounce(() => {
    const { clientWidth: width, clientHeight: height } = document.documentElement
    user.setViewport(width, height)
  }, 500))
}
