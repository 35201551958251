import { logger } from '../../../logger/src/index.js'
import Strategy from './Strategy.js'
import container from '../di.js'

import { mcu2 } from '../messages/index.js'

import {
  KurentoHub,
  KurentoEndpoint
} from '../kurento/index.js'

class MCUStrategy extends Strategy {
  constructor() {
    super()
    this.name = 'mcu2'
    this.listeners = []
    this.peers = {}
    this.endpoints = {}
    this.configuration = {
      rpc: {
        'mcuv2:destroyed': this.onDestroyed,
        'mcuv2:candidate': this.handleRemotePeerCandidateSignal,
        'con:ready': this.onReadyHandler,
        'mcuv2:created': this.onCreatedHandler,
        'mcuv2:connections': this.onConnectionsHandler,
        'mcuv2:record': this.onRecordHandler,
        'mcuv2:recorderCreated': this.onRecorderCreatedHandler,
        'mcuv2:recorderDestroying': this.onRecorderDestroyingHandler,
        'mcuv2:recorderDestroyed': this.onRecorderDestroyedHandler,
        'plt:notification': this.onNotificationHandler
      }
    }
    this.isRecording = false
  }

  async initialize() {
    this.createVideoEndpoint()
    this.createAudioDataHub()
      .then(this.createAudioDataEndpoint())
  }





  /*
   *
   * Kurento Hubs
   * 
  */



  createAudioDataHub () {
    const hubId = `hub:${container.session.user.party}-${container.session.user.id}:audio-data`
    logger('communication', 'info', `creating audio/data hub: ${hubId}`)

    const options = {
      id: hubId,
      private: true,
      type: 'composite',
      publishesTo: hubId,
      subscribesTo: ['group:audio-data'],
      exclude: [`${container.session.user.party}-${container.session.user.id}:audio-data`]
    }

    return new Promise((resolve) => {
      new KurentoHub(options, () => resolve())
    })
  }

  createRecorderHub = () => {
    const hubId = `hub:recorder`
    logger('communication', 'info', `creating recorder hub: ${hubId}`)
    
    const options = {
      id: hubId,
      type: 'alpha_blending',
      publishesTo: hubId,
      subscribesTo: ['group:audio-data', 'camera'],
    }

    return new Promise((resolve) => {
      new KurentoHub(options, () => resolve())
    })
  }


  /*
   *
   * Kurento Endpoints
   * 
  */



  createVideoEndpoint () {
    const { video } = container.localStream
    const endpointId = `${container.session.user.party}-${container.session.user.id}:camera`

    const options = {
      id: endpointId,
      ...(container.session.user.party === 'client' && { publishesTo: 'camera' }),
      ...((container.session.user.party === 'assistant' || container.session.user.party === 'observer') && { subscribesTo: 'camera' }),
    }

    const transceivers = {
      video: container.session.user.party === 'client' ? 'sendonly': 'recvonly'
    }

    const channels = ['video']

    this.endpoints[endpointId] = {
      endpoint: new KurentoEndpoint(options, transceivers, channels, video.stream.value)
        .subscribe('stream', (data) => {
          logger('communication', 'info', `${endpointId} received stream`)
          this.publish({ 
            type: 'strategy:stream:video',
            data: {
              type: 'video',
              stream: data.stream
            } 
          })
        })
        .subscribe('ready', () => {
          logger('communication', 'info', `${endpointId} received ready`)
          if (this.isStrategyReady()) {
            this.publish({ type: 'strategy:ready' })
          }
        })
    }
  }

  createAudioDataEndpoint (stream) {
    const { audio } = container.localStream
    const endpointId = `${container.session.user.party}-${container.session.user.id}:audio-data`
    logger('communication', 'info', `creating audio/data endpoint: ${endpointId}`)

    const options = {
      id: endpointId,
      publishesTo: 'group:audio-data',
      subscribesTo: [`hub:${container.session.user.party}-${container.session.user.id}:audio-data`],
    }
    
    const transceivers = {
      audio: 'sendrecv'
    }

    const channels = ['data', 'audio']

    this.endpoints[endpointId] = {
      endpoint: new KurentoEndpoint(options, transceivers, channels, audio.stream.value)
        .subscribe('stream', (data) => {
          logger('communication', 'info', `${endpointId} received stream`)
          this.publish({ 
            type: 'strategy:stream:audio',
            data: {
              type: 'audio',
              stream: data.stream
            }
          })
        })
        .subscribe('ready', () => {
          logger('communication', 'info', `${endpointId} received ready`)
          if (this.isStrategyReady()) {
            this.publish({ type: 'strategy:ready' })
          }
        })
        .subscribe('data', (data) => {
          this.handleData(data)
        })
    }
  }

  createPresenterScreensharingEndpoint (stream) {
    const endpointId = `${container.session.user.party}-${container.session.user.id}:screensharing`
    logger('communication', 'info', `creating screensharing endpoint (presenter): ${endpointId}`)

    const options = {
      id: endpointId,
      publishesTo: 'screensharing',
    }

    const transceivers = {
      video: 'sendonly'
    }

    const channels = ['screensharing']

    this.endpoints[endpointId] = {
      endpoint: new KurentoEndpoint(options, transceivers, channels, stream)
        .subscribe('stream', (data) => {
          this.publish({ 
            type: 'strategy:screensharingStarted:presenter',
            data: {
              type: 'screensharing',
              ...data
            } 
          })
        })
    }
  }
  
  createViewerScreensharingEndpoint () {
    const endpointId = `${container.session.user.party}-${container.session.user.id}:screensharing`
    logger('communication', 'info', `creating screensharing endpoint (viewer): ${endpointId}`)

    const options = {
      id: endpointId,
      subscribesTo: 'screensharing',
    }

    const transceivers = {
      video: 'recvonly'
    }

    const channels = ['screensharing']

    this.endpoints[endpointId] = {
      endpoint: new KurentoEndpoint(options, transceivers, channels, undefined)
        .subscribe('stream', (data) => {
          this.publish({ 
            type: 'strategy:screensharingStarted:viewer',
            data: {
              type: 'screensharing',
              ...data
            } 
          })
        })
    }
  }



  /*
   *
   * Broadcast
   * 
  */



  broadcast = (data) => {
    for (const endpoint in this.endpoints) {
      if (this.endpoints[endpoint].endpoint.channels.includes('data')) {
        logger('communication', 'info', `broadcasting data`, {
          endpoint: this.endpoints[endpoint]
        })
        this.endpoints[endpoint].endpoint.broadcast(data)
      }
    }
  }



  /*
   *
   * Recording
   * 
  */

  startRecording = (conference) => {
    this.createRecorderHub()
      .then(() => {
        this.createRecorder(conference)
      })
  }

  stopRecording = () => {
    this.destroyRecorder()
  }

  createRecorder = (conference) => {
    const message = mcu2.createRecorderMessage({
      id: 'recorder:video',
      subscribesTo: ['hub:recorder'],
      filename: `airelink_${conference}_${Date.now()}.webm`
    })
    container.jsonrpc.send(message, (error, response) => {
      if (error) {
        console.error(error)
      } else {
        const { reference } = response
        container.session.setRecorderReference(reference)
      }
    })
  }

  destroyRecorder = () => {
    const message = mcu2.destroyRecorderMessage('recorder:video')
    container.jsonrpc.send(message, (error, response) => {
      if (error) {
        console.error(error)
      }
    })
  }
  


  /*
   *
   * Handlers
   * 
  */



  handleRemotePeerCandidateSignal = (data) => {
    if (data.id && this.endpoints[data.id]) {
      logger('communication', 'info', 'received candidate', {
        id: data.id,
        candidate: data.ice,
      })
      this.endpoints[data.id].endpoint.signal('candidate', data.ice)
    }
  }

  handleData = (data) => {
    const message = this.receiver.receive(data)
    data = JSON.parse(data)
    if (message.isComplete) {
      if (Number(message.data._from) !== Number(container.session.user.id)) {
        this.publish({ type: 'strategy:data', data: message.data })
      }
    } else if (message.data?.meta?.type) {
      const { type: queue, meta: { type }} = message.data
      const { size, numberOfChunksReceived } = this.receiver.getQueue(queue)
      const progress = numberOfChunksReceived / size
      this.publish({ type: 'strategy:data:progress', data: { type, progress } })
    }
  }

  onReadyHandler = (data) => {
    this.publish({ type: 'strategy:counterpartyReady', data })
  }

  onCreatedHandler = () => {}

  onConnectionsHandler = (data) => {
    this.publish({ type: 'strategy:connections', data })
  }

  onRecordHandler = (data) => {
    logger('communication', 'info', 'received mcuv2:record', { data })
    if (!this.isRecording) {
      this.isRecording = true
      this.publish({ type: 'strategy:recording:started', data })
    }
    
  }

  onRecorderCreatedHandler = (data) => {
    logger('communication', 'info', 'received mcuv2:recorderCreated', { data })
    this.publish({ type: 'strategy:recording:started', data })
  }

  onRecorderDestroyingHandler = (data) => {
    logger('communication', 'info', 'received mcuv2:recorderDestroying', { data })
    this.publish({ type: 'strategy:recording:stopping', data })
  }

  onRecorderDestroyedHandler = (data) => {
    logger('communication', 'info', 'received mcuv2:recorderDestroyed', { data })
    this.publish({ type: 'strategy:recording:stopped', data })
    container.session.clearRecorderReference()
    this.isRecording = false
  }

  onNotificationHandler = (data) => {
    this.publish({ type: 'strategy:notification', data })
  }


  /*
   *
   * Helpers
   * 
  */



  isStrategyReady () {
    let ready = true
    for (const endpoint in this.endpoints) {
      if (!this.endpoints[endpoint].endpoint.isReady) {
        ready = false
      }
    }
    return ready
  }



  /*
   *
   * Destroy
   * 
  */



  destroy = () => {
    return new Promise(async (resolve) => {
      for (const endpoint in this.endpoints) {
        logger('communication', 'info', `destroying: ${endpoint}`, this.endpoints[endpoint])
        await this.endpoints[endpoint].endpoint.destroy()
      }
      resolve()
    })
  }

  destroyScreensharingEndpoint = () => {
    const target = this.getEndpoint('screensharing')
    if (target) {
      target.endpoint.destroy()
    }
  }
}



export default MCUStrategy
