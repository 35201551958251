import { ref, computed } from 'vue'
import { useSupported, useEventListener } from '@vueuse/core'
export default function useDevicesList () {
  const devices = ref([])
  const videoInputs = computed(() => devices.value.filter(device => device.kind === 'videoinput'))
  const audioInputs = computed(() => devices.value.filter(device => device.kind === 'audioinput'))
  const audioOutputs = computed(() => devices.value.filter(device => device.kind === 'audiooutput'))
  const isSupported = useSupported(() => navigator && navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)

  const update = async () => {
    if (!isSupported.value) return
    devices.value = await navigator.mediaDevices.enumerateDevices()
  }

  useEventListener(navigator.mediaDevices, 'devicechange', update)
  update()
  return { 
    isSupported,
    devices,
    update,
    videoInputs,
    audioInputs,
    audioOutputs
  }
}
