import container from '@di'
import { useSessionStore, useAppStore } from '@/stores'

export default class BackendNotificationProcedure {
  constructor () {
    this.name = 'backendNotificationProcedure'
    this.session = useSessionStore()
    this.app = useAppStore()
    window.test = this.test
  }

  install = () => {
    container.messenger.subscribe(this.name, 'strategy:notification', this.handler)
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  handler = (notification) => {
    switch (notification.action) {
      case 'participantsChanged':
        // todo:
        // const { data: participant } = notification 
        // this.session.createParticipant(participant)
        this.updateSession()
        break
    }
  }

   updateSession = async () => {
    const { configuration } = this.app
    const { app: { type: appType }, session: { api: sessionApiEndpoint }} = configuration
    const { key, conference: callId, info } = this.session

    try {
      const currentParticipants = info.participants.map((participant) => participant.id)
      const session = await container.session.getApiSessionInfo(sessionApiEndpoint, appType, key, callId, false)
      const newParticipants = session.participants.map((participant) => participant.id)

      const addedParticipantsIds = newParticipants.filter(id => !currentParticipants.includes(id))
      const addedParticipantsProfiles = session.participants.filter((participant) => addedParticipantsIds.includes(participant.id))
   
      for (const participant of addedParticipantsProfiles) {
        this.session.createParticipant(participant)
      }

      this.session.setInfo(session)

    } catch (error) {
      console.error(error)
    }
  }
}
