export const muteMessage = (participantId, deviceType) => {
  return {
    type: 'participant:mute',
    data: {
      id: participantId,
      deviceType
    }
  }
}


