<template>
  <a-layout-page class="exit" size="x-wide" bar>
    <template #header>
      <a-logo brand class="me--auto" />
      <a-button 
        variant="outline-primary"
        label
        @click="reload"
      >
        <a-icon name="sync" />   
        <span class="ms--2">{{ $t('exit.step2.joinAgain') }}</span>
      </a-button>
    </template>
    <template #content>
      <div class="pt--5">
        <a-transition
          name="slide-up-fade"
          :config="{ delay: { enter: 300 } }"
          group
        >
          <feedback-view v-if="phase === 'feedback'" @next="feedbackNextHandler" />
          <summary-view v-if="phase === 'summary'" />
          <final-view v-if="phase === 'final'" />
        </a-transition>
      </div>
    </template>
  </a-layout-page>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import {
  useUserStore,
  useExitStore,
  useSessionStore
} from '@/stores'
import {
  useRepository,
  useGoogleService
} from '@/composables'
import { locationMessages } from '@/messages'

const user = useUserStore()
const exit = useExitStore()
const session = useSessionStore()

const {
  sendWsEvent,
  sendStats
} = useRepository()
const {
  getDirections,
  getMapImage,
  getLink
} = useGoogleService()

const phase = ref(user.isObserver ? 'final' : 'feedback')

const reload = () => {
  window.location.reload()
}

function feedbackNextHandler () {
  const { hasSummary, isSolved } = exit
  phase.value = hasSummary && isSolved ? 'summary' : 'final'
}

function createSummary () {
  const { latitude: origLat, longitude: origLng, source: origSource } = exit.location
  const { latitude: destLat, longitude: destLng, source: destSource } = user.location
  if (origLat && origLng && destLat && destLng) {
    const origin = `${origLat},${origLng}`
    const destination = `${destLat},${destLng}`
    getDirections({ origin, destination }).then((result) => {
      const { status } = result
      if (status === 'OK') {
        const { legs, overview_polyline: polyline } = result.routes[0]
        const distance = legs.reduce((total, leg) => total + leg.distance.value, 0) * 2
        const duration = legs.reduce((total, leg) => total + leg.duration.value, 0) * 2
        const inacurrate = [origSource, destSource].includes('ip')
        const link = getLink({ origin, destination })
        exit.setData(distance, duration, inacurrate, link)
        sendWsEvent(locationMessages.pathDirections(polyline.points))
        sendStats({
          directionsDistance: distance,
          directionsDuration: duration
        })
        getMapImage(polyline.points).then((image) => {
          exit.setImage(image)
        }).catch((error) => {
          console.error(`googleService.getMapImage: ${error}`)
        })
      } else {
        const { error_message: errorMessage } = result
        console.error(`googleService.getDirections: [${status}]${errorMessage ? ' ' + errorMessage : ''}`)
      }
    })
  }
}

onMounted(() => {
  if (user.isAssistant) {
    createSummary()
  }
})

// TODO: stats call duration
</script>

<style lang="scss">
$exit-offset: map-get($spacers, 4);
$exit-margin: map-get($spacers, 4);
$exit-question-btn-width: 7rem;
$exit-summary-map-text-color: $gray-300;
$exit-issues-small-spacer: map-get($spacers, 1);
$exit-issues-padding-x: $box-spacer-lg;

.exit {
  z-index: $zindex-default;
  &__wrapper {
    position: relative;
    margin-top: $exit-margin;
  }
  &--question {
    .box > .form__radio label {
      padding: $box-spacer;
    }
  }
  &__summary {
    &__map {
      text-align: center;
      img {
        border: 1px solid $primary;
        border-radius: $border-radius;
      }
      p {
        color: $exit-summary-map-text-color;
        font-size: $font-size-sm;
        margin: map-get($spacers, 1) 0 0;
      }
      &__pane {
        position: relative;
        &__action {
          transition-property: opacity;
          transition-duration: $duration-fast;
          position: absolute;
          left: 0;
          top: 50%;
          width: 100%;
          transform: translateY(-50%);
          opacity: 0;
          :hover > & {
            opacity: 1;
          }
        }
      }
    }
  }
  &__issues {
    small {
      margin-left: $exit-issues-small-spacer;
    }
  }
  &__image {
    &--bottom {
      left: 50%;
      top: var(--top, 300px);
      max-width: 100%;
      max-height: calc(100% - var(--top, 300px));
      transform: translateX(-50%);
    }
  }
  @media (max-height: 660px) {
    &__image {
      display: none;
    }
  }
  @media (max-width: $breakpoint-sm) {
    $pictogram-size: 30px;
    .logo {
      width: $pictogram-size;
      overflow: hidden;
    }
  }
  @media (min-width: $breakpoint-sm) {
    &__issues {
      padding-left: $exit-issues-padding-x;
      padding-right: $exit-issues-padding-x;
    }
  }
}
</style>
