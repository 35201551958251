import { watch } from 'vue'
import { logger } from '@logger'
import container from '@di'
import { signal } from '@/messages'
import { useAppMachine } from '@/state'
import { useUserStore, useSignalStore } from '@/stores'
import { useRepository } from '@/composables'
import { roundDecimals } from '@/utils'

export default class SignalProcedure {
  constructor () {
    this.name = 'signalProcedure'
    this.user = useUserStore()
    this.signal = useSignalStore()
    this.breakpoints = [0.3, 0.75, 2]
    this.threshold = 100
    this.duration = 1000
    this.interval = undefined
    this.errorsAllowed = 5
    this.forceSignal = false
    this.timestamp = 0
    this.entries = []
    this.errors = 0
    this.checkConnection()
  }

  install = () => {
    const { service, state } = useAppMachine()
    container.messenger.subscribe(this.name, 'strategy:data', this.handler)
    service.subscribe((_, event) => {
      if (this.user.isAssistant) {
        if (event?.type === 'START_CALL') {
          this.start()
        } else if (event?.type === 'lobby' || event?.type === 'exit') {
          this.stop()
        } 
      }
    })
    watch(() => this.user.isAssistant, (val) => {
      if (!state.value.matches('app.view.meeting.lobby')) {
        if (val) {
          this.start()
        } else {
          this.stop()
        }
      }
    })
  }
  
  uninstall = () => {
    container.messenger.unsubscribe(this.name)
  }

  send = (message) => {
    const { agent } = container
    agent.broadcast(message)
  }

  handler = (data) => {
    const { type, start } = data
    if (type === 'signal:round:trip') {
      if (this.user.isAssistant) {
        const end = Math.round(window.performance.now())
        const collation = (end - start) > this.threshold
        this.entries.push({
          start,
          end
        })
        this.timestamp = 0
        this.aggregateErrors(collation)
      } else if (this.user.isClient) {
        this.send(signal.roundTripMessage(start))
      }
    }
  }

  start = () => {
    if (!this.interval) {
      this.interval = setInterval(() => {
        if (this.timestamp > 0) {
          this.entries.push({
            start: this.timestamp,
            end: null
          })
          this.aggregateErrors(true)
        }
        const start = Math.round(window.performance.now())
        this.send(signal.roundTripMessage(start))
        this.timestamp = start
      }, this.duration)
      logger(this.name, 'info', 'round-trip time measurement started')
    }
  }

  stop = () => {
    this.interval = clearInterval(this.interval)
    this.calculateStats()
    this.timestamp = 0
    this.entries = []
    this.errors = 0
    logger(this.name, 'info', 'round-trip time measurement stopped')
  }

  checkConnection = () => {
    const { connection } = navigator
    if (connection) {
      this.setSignal(this.getSignal(connection.downlink))
      connection.addEventListener('change', () => {
        this.setSignal(this.getSignal(connection.downlink))
      })
    }
  }

  getSignal = (num) => {
    const signals = [
      ...this.breakpoints,
      ...(this.breakpoints.includes(num) ? [] : [num])
    ].sort((a, b) => a - b)
    return signals.indexOf(num)
  }

  setSignal = (level) => {
    const [min] = this.breakpoints
    const warning = level < min
    this.signal.setLevel(level)
    this.signal.setWarning(warning)
    this.forceSignal = warning
  }

  aggregateErrors = (fail) => {
    this.errors = fail ? this.errors + 1 : 0
    const exceeded = this.forceSignal || this.errors > this.errorsAllowed
    this.signal.setWarning(exceeded)
  }

  calculateStats () {
    if (this.entries.length) {
      const { sendWsEvent } = useRepository()
      const { durations, lost } = this.entries.reduce((obj, item) => {
        if (item.end) {
          obj.durations.push(item.end - item.start)
        } else {
          obj.lost.push(item.start)
        }
        return obj
      }, {
        durations: [],
        lost: []
      })
      durations.sort((a, b) => a - b)
      const { 0: min, length: l, [l - 1]: max } = durations
      let average = durations.reduce((a, b) => a + b, 0) / this.entries.length
      average = roundDecimals(average, 2)
      const total = lost.length
      const percent = Math.round(total / this.entries.length * 10000) / 100
      sendWsEvent(signal.roundTripTime({
        min,
        max,
        average,
        lost: {
          total,
          percent,
          timestamps: lost
        }
      }))
    }
  }
}
