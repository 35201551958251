import JsonRpc from './JsonRpc.js'
import { MCUStrategy } from './strategies/index.js'
import Connection from './Connection.js'
import Repository from './Repository.js'
import container from './di.js'

export default class Mediator {
  constructor (configuration, agent) {
    container.jsonrpc = new JsonRpc(configuration)
    this.configuration = configuration
    this.agent = agent
    this.strategies = {}
    this.repository = null
    this.connection = null
    this.addStrategy(new MCUStrategy())
  }

  addStrategy (strategy) {
    this.strategies[strategy.name] = strategy
    container.jsonrpc.addConfiguration(strategy.configuration)
  }

  call = () => {
    this.connection = new Connection(this.strategies.mcu2).initialize()
    this.repository = new Repository(this.configuration)
  }

  send (message, callback) {
    container.jsonrpc.send(message, callback)
  }

  broadcast = (data) => {
    if (this.connection) {
      this.connection.broadcast(data)
    }
  }

  connect () {
    container.jsonrpc.connect()
  }

  destroy = () => {
    return this.connection.destroy()
  }
}
