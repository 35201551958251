<template>
  <div class="layout--size-base mx--auto">
    <a-button
      v-if="user.isAssistant"
      variant="primary"
      block
      class="mb--3"
      @click="inviteHandler"
    >
      {{ $t('exit.step2.invite') }}
    </a-button>
    <div class="row">
      <div class="col--2">
        <a-button
          variant="outline-primary"
          label
          block
          @click="shareHandler"
          value="twitter"
        >
          <a-icon name="x" />
          <span class="ms--2">{{ $t('exit.step2.share.twitter') }}</span>
        </a-button>
      </div>
      <div class="col--2">
        <a-button
          variant="outline-primary"
          label
          block
          @click="shareHandler"
          value="linkedin"
        >
          <a-icon name="linkedin" />
          <span class="ms--2">{{ $t('exit.step2.share.linkedin') }}</span>
        </a-button>    
      </div>
    </div>
  </div>
</template>

<script setup>
import { useUserStore, useExitStore } from '@/stores'
import { useTranslation } from 'i18next-vue'

const user = useUserStore()
const exit = useExitStore()
const { t } = useTranslation()

function inviteHandler () {
  window.open('https://app.aire.link/work/sessions?open=referral', '_blank', 'noopener')
}

function shareHandler (event) {
  const { value } = event.target
  const url = 'https://aire.link'
  let message = t('exit.step2.share.staticMessage')
  if (exit.hasSummary) {
    message = t('exit.step2.share.dynamicMessage', {
      distance: exit.distanceSaved,
      emissions: exit.co2Saved
    })
  }
  const map = {
    twitter: `https://twitter.com/share?text=${message}&url=${url}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
  }
  window.open(map[value], '_blank', 'noopener')
}
</script>

<style lang="scss">
</style>
