import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { useUserStore } from '@/stores'

export default defineStore('session', () => {
  const user = useUserStore()
  const info = ref({})
  const type = import.meta.env.VITE_APP_TYPE
  const conference = ref(null)
  const key = ref(null)
  const participants = ref([])
  const connections = ref([])
  const configuration = ref(null)
  const startTimestamp = ref(null)
  const duration = ref(0)

  const users = computed(() => [user, ...participants.value])
  const hasClient = computed(() => participants.value.find((participant) => participant.party === 'client' && participant.profile))
  const hasAssistant = computed(() => participants.value.find((participant) => participant.party === 'assistant'))
  const hasObserver = computed(() => participants.value.find((participant) => participant.party === 'observer'))

  const client = computed(() => participants.value.find((participant) => participant.party === 'client'))
  const assistant = computed(() => participants.value.find((participant) => participant.party === 'assistant'))
  const observers = computed(() => participants.value.find((participant) => participant.party === 'observer'))

  const transform = computed(() => {
    if (type.value === 'client') return false
    const [client] = participants.value.filter((item) => item.party === 'client')
    const { width, height } = client.profile.viewport
    const { width: userWidth, height: userHeight } = user.profile.viewport
    const scale = Math.min(userWidth / width, userHeight / height)
    const isWider = width / height > userWidth / userHeight
    const top =  isWider ? (userHeight - (height * scale)) / 2 : 0
    const left = isWider ? 0 : (userWidth - (width * scale)) / 2
    return {
      scale,
      left,
      top,
      isWider,
      client: {
        width,
        height
      },
      user: {
        width: userWidth,
        height: userHeight
      }
    }
  })

  const hasTorchCapability = computed(() => {
    if (participants.value.length) {
      const client = participants.value.find((participant) => participant.party === 'client')
      return client?.profile?.hasTorchCapability
    }
    return false
  })

  const torchStatus = computed(() => {
    if (participants.value.length) {
      const client = participants.value.find((participant) => participant.party === 'client')
      return client?.profile?.torchStatus
    }
    return false
  })

  const time = computed(() => new Date(duration.value * 1000).toISOString().substring(11, 19))

  let interval = 0

  function createParticipant (participant) {
    this.info.participants.push(participant)
  }

  // QUICK FIX: If the client does not have a camera, it does not broadcast a profile (!communication.isReady).
  function addParticipant (participant) {
    this.participants.push({
      ...participant,
      ...(participant.party === 'client' && {
        profile: {
          camera: 'not-available'
        }
      })
    })
  }
  
  function removeParticipant (participantId) {
    participants.value = this.participants.filter((participant) => Number(participant.id) !== Number(participantId))
  }

  function getParticipant (participantId) {
    return this.participants.find((participant) => Number(participant.id) === Number(participantId))
  }

  function getParticipantInfo (participantId) {
    return this.info.participants.find((participant) => Number(participant.id) === Number(participantId))
  }

  function setInfo (info) {
    this.$patch({ info })
  }

  function setConfiguration (configuration) {
    this.$patch({ configuration })
  }

  function setStartTimestamp (startTimestamp) {
    this.$patch({ 
      startTimestamp
    })
  }

  function updateParticipantProfile (id, profile) {
    this.$patch(({ participants }) => {
      const index = participants.findIndex((participant) => Number(participant.id) === Number(id))
      if (index !== -1) {
        participants[index].profile = profile 
      }
    })
  }
  
  function updateParticipant (id, data) {
    this.$patch(({ participants }) => {
      const index = participants.findIndex((participant) => Number(participant.id) === Number(id))
      if (index !== -1) {
        participants[index] = Object.assign(participants[index], data)
      }
    })
  }

  function updateConnections (data) {
    this.connections = data
  }

  function startTimer () {
    interval = setInterval(() => {
      duration.value = (Date.now() - startTimestamp.value) / 1000
    }, 1000)
  }

  function stopTimer () {
    clearInterval(interval)
  }

  return {
    info,
    conference,
    key,
    participants,
    users,
    connections,
    configuration,
    hasClient,
    hasAssistant,
    hasObserver,
    client,
    assistant,
    observers,
    transform,
    startTimestamp,
    duration,
    time,
    createParticipant,
    addParticipant,
    removeParticipant,
    getParticipant,
    getParticipantInfo,
    hasTorchCapability,
    torchStatus,
    setInfo,
    setConfiguration,
    updateParticipantProfile,
    updateParticipant,
    updateConnections,
    startTimer,
    stopTimer,
    setStartTimestamp
  }
})
