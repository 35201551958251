<template>
  <div
    :class="[
      'draw-video',
      !pip && 'not--pip',
      pictureInPictureEnabled && 'is--pip-enabled'
    ]"
  >
    <video
      ref="video"
      class="media media--contain"
      autoplay
      muted
      playsinline
      @enterpictureinpicture="enterPipHandler"
      @leavepictureinpicture="leavePipHandler"
    ></video>
    <div v-if="pictureInPictureEnabled" class="draw-video__controls">
      <a-button
        variant="link-default"
        @click="pipHandler"
      >
        <a-icon :name="`window-${pip ? 'minimize' : 'maximize'}`" />      
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, onUnmounted } from 'vue'
import { useKurentoStream } from '@/composables'

const { video: kurentoVideoStream } = useKurentoStream()
const video = ref(null)
const pip = ref(false)

const { pictureInPictureEnabled } = document

function pipHandler () {
  if (pip.value) {
    document.exitPictureInPicture()
  } else {
    video.value.requestPictureInPicture()
  }
}

function enterPipHandler () {
  pip.value = true
}

function leavePipHandler () {
  pip.value = false
}

watchEffect(() => {
  if (video?.value) {
    video.value.srcObject = kurentoVideoStream.value
    // TODO: remove? (separate audio channel)
    video.value.muted = false
  }
})

onUnmounted(() => {
  if (pip.value) {
    document.exitPictureInPicture()
  }
})
</script>

<style lang="scss">
$video-bg: $black;
$video-size: 12rem;
$video-border: 1px solid $white;
$video-bottom-offset: ($component-height + $layout-spacer-sm);
$video-btn-color: $white;
$video-btn-backdrop: rgba($gray-500, 0.4);

.draw-video {
  position: absolute;
  width: $video-size;
  height: $video-size;
  right: $layout-spacer;
  bottom: ($video-bottom-offset + $layout-spacer);
  background-color: $video-bg;
  border: $video-border;
  border-radius: $border-radius;
  overflow: hidden;
  z-index: $zindex-component;
  &__controls {
    transition-property: opacity;
    transition-duration: $duration-fast;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $video-btn-backdrop;
    color: $video-btn-color;
    .btn {
      width: inherit;
      height: inherit;
    }
    .not--pip > & {
      opacity: 0;
      &:hover {
        opacity: 1;
      }
    }
  }
  .media:picture-in-picture {
    display: none;
  }
  @media (max-width: ($breakpoint-sm)), (max-height: ($breakpoint-sm)) {
    width: $component-height;
    height: $component-height;
    right: $box-spacer;
    bottom: ($video-bottom-offset + $box-spacer);
    border: 0;
    &:not(.is--pip-enabled) {
      display: none;
    }
    &__controls {
      color: $primary;
      background-color: rgba($white, 0.8);
      .not--pip > & {
        opacity: 1;
      }
    }
    .media {
      display: none;
    }
  }
}
</style>
