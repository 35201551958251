import jsonrpc from 'kurento-jsonrpc'
import deepmerge from 'deepmerge'
import Websocket from './Websocket.js'


export default class JsonRpc {
  constructor (configuration) {
    this.websocket = new Websocket(configuration)
    this.configuration = {
      ws: {},
      rpc: {}
    }
    this.configuration = deepmerge(this.configuration, this.websocket.configuration)
  }

  addConfiguration (configuration) {
    this.configuration = deepmerge(this.configuration, configuration)
  }

  connect () {
    this.jsonRpcClient = new jsonrpc.clients.JsonRpcClient(this.configuration)
  }

  close () {
    this.jsonRpcClient.close()
    this.websocket.status = 'DISCONNECTED'
  }

  send (message, callback) {
    return this.jsonRpcClient.send(message.method, message.params, callback || null)    
  }
}
