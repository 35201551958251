<template>
  <a-button-box class="indicator px--2">
    {{ session.time }}
    <a-divider class="ms--2 me--1" />
    <meeting-indicator-signal
      v-if="signal.isAvailable"
      :level="signal.level"
    />
    <div v-if="signal.warning" class="pte--auto">
      <meeting-tools-popper
        :content="$t('indicator.signal.warning')"
      >
        <a-icon
          name="connection-off"
          :width="16"
          :height="16"
          class="text--warning"
        />
      </meeting-tools-popper>
    </div>
    <a-icon
      v-if="app.state.matches('app.recording.on')"
      name="record"
      class="text--danger indicator__recording"
      :width="16"
      :height="16"
    />
   </a-button-box>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'
import { useAppStore, useSessionStore, useSignalStore } from '@/stores'

const app = useAppStore()
const session = useSessionStore()
const signal = useSignalStore()

onMounted(() => {
  session.startTimer()
})

onUnmounted(() => {
  session.stopTimer()
})

</script>

<style lang="scss">
$indicator-signal-opacity: 0.3;

.indicator {
  &__signal {
    &--0 rect,
    &--1 rect:nth-child(1) ~ rect,
    &--2 rect:nth-child(3) {
      opacity: $indicator-signal-opacity;
    }
  }
  &__recording {
    animation: 1s pulse linear infinite;
  }
  .a-divider {
    & ~ * {
      margin-left: map-get($spacers, 1);
    }
    &:last-child {
      display: none;
    }
  }
}
</style>
